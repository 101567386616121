import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui";
import {
  ValidateCI,
  ValidateDate,
  ValidateEmail,
  ValidatePhone,
} from "../shared/regex";
import useMobile from "../../hooks/useOrientation";
import ProgressBar from "@ramonak/react-progress-bar";
import { warning } from "../../ui/colors";
import {
  SurpriseRegister,
  SurpriseGuess,
  SurpriseResults,
  RegisterSecondStep,
} from "./surpirse";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { database } from "../../config";
import { getHighSchools } from "../../services/passengerService";
import Modal from "react-modal";
import Bugsnag from "@bugsnag/js";
import WheelGame from "./WheelGame";

const backgroundImage = require("../../assets/fondo_sorpresa.png");
const backgroundImageMobile = require("../../assets/fondo_sorpresa_mobile.png");
const finishMobile = require("../../assets/finish_mobile.png");
const finishDesktop = require("../../assets/finish_desktop.png");

const minion = require("../../assets/minion.png");
const minion2 = require("../../assets/minion2.gif");

const styles = {
  dateInput: {
    paddingTop: 16.5,
    paddingBottom: 16.5,
    fontSize: "1rem",
    paddingLeft: 14,
    paddingRight: 14,
    fontFamily: "Gilroy",
    marginBottom: 8,
    backgroundColor: "rgba(255,255,255,0.7)",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.38)",
  },
  subtitle: {
    alignSelf: "center",
    color: "white",
  },
  sentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  socialContainer: {
    display: "flex",
    gap: 20,
    paddingTop: 20,
  },
  socialLogoStyles: {
    width: 40,
    height: "auto",
    cursor: "pointer",
  },
  termsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    minHeight: 500,
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    padding: 25,
    color: "white",
  },
};

const option_days = {
  title: "¿Cuántos días durará la sorpresa?",
  key: "days",
  label: "¿Cuántos días durará la sorpresa?",
  hasBonus: false,
  options: [
    {
      id: 0,
      name: "0",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3",
    },
    {
      id: 4,
      name: "4 o más",
    },
  ],
};

const option_partys = {
  title: "¿Cuántas fiestas formarán parte de la sorpresa?",
  key: "partys",
  label: "¿Cuántas fiestas formarán parte de la sorpresa?",
  hasBonus: false,
  options: [
    {
      id: 0,
      name: "0",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3 o más",
    },
  ],
};

const option_parks = {
  title: "¿Cuántos parques nuevos visitaremos en la sorpresa?",
  key: "parks",
  label: "¿Cuántos parques nuevos visitaremos en la sorpresa?",
  hasBonus: false,
  options: [
    {
      id: 0,
      name: "0",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2 o más",
    },
  ],
};

//La sorpresa dará la posibilidad de visitar esta cantidad de ciudades nuevas:
const option_cities = {
  title: "Qué cantidad de ciudades nuevas visitaremos?",
  key: "cities",
  label:
    "La sorpresa dará la posibilidad de visitar esta cantidad de ciudades nuevas:",
  hasBonus: false,
  options: [
    {
      id: 0,
      name: "0",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3",
    },
  ],
};

//La sorpresa nos permitirá viajar a estos nuevos países:
const option_countries = {
  title: "Qué cantidad de paises nuevos visitaremos?",
  key: "countries",
  label: "La sorpresa nos permitirá viajar a estos nuevos países:",
  hasBonus: true,
  options: [
    {
      id: 0,
      name: "0",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3",
    },
  ],
};

//La sorpresa incluirá la visita a un espectáculo deportivo de la siguiente disciplina:
const option_sports = {
  title: "La sorpresa incluirá la visita a un espectáculo de:",
  key: "sports",
  label:
    "La sorpresa incluirá la visita a un espectáculo deportivo de la siguiente disciplina:",
  hasBonus: false,
  options: [
    {
      id: 0,
      name: "Basketball",
    },
    {
      id: 1,
      name: "Football",
    },
    {
      id: 2,
      name: "Tenis",
    },
    {
      id: 3,
      name: "Football americano",
    },
    {
      id: 4,
      name: "Baseball",
    },
    {
      id: 5,
      name: "Ninguno",
    },
  ],
};

//La sorpresa incluirá la presencia de un famoso
const option_famous = {
  title: "La sorpresa incluirá la presencia de un famoso:",
  key: "famous",
  label: "La sorpresa incluirá la presencia de un famoso, que tipo de famoso?",
  hasBonus: true,
  options: [
    {
      id: 0,
      name: "Músico",
    },
    {
      id: 1,
      name: "Instagrammer",
    },
    {
      id: 2,
      name: "TikToker",
    },
    {
      id: 3,
      name: "Streamer",
    },
    {
      id: 4,
      name: "Actor",
    },
    {
      id: 5,
      name: "Ninguno",
    },
  ],
};

//La sorpresa incluirá un viaje en estos medios de transporte (pueden ser más de uno)
const option_transport = {
  title: "La sorpresa incluirá un viaje en estos medios de transporte:",
  key: "transport",
  label:
    "La sorpresa incluirá un viaje en estos medios de transporte (pueden ser más de uno)",
  hasBonus: false,
  options: [
    {
      id: 0,
      name: "Ninguno extra",
    },
    {
      id: 1,
      name: "Avión",
    },
    {
      id: 2,
      name: "Barco",
    },
    {
      id: 3,
      name: "Bus",
    },
    {
      id: 4,
      name: "Tren",
    },
    {
      id: 5,
      name: "Bicicletas",
    },
  ],
};

export const categories = [
  option_days,
  option_partys,
  option_parks,
  option_cities,
  option_countries,
  option_famous,
  option_sports,
  option_transport,
];

const STEPS = 11;

const RegisterScreen = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const isMobile = useMobile();
  const [wheelGameOpen, setWheelGameOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [data, setData] = useState({
    participantPhone: "",
    participantDocumentId: "",
    participantBirthDate: "",
    highschool: "",
    highschoolCustom: "",
    highschoolName: "",
    name: "",
    lastName: "",
    birthDate: "",
    documentId: "",
    family: "",
    phone: "",
    year: "",
    ammount: 0,
  });
  const [participantData, setParticipantData] = useState();
  const [loading, setLoading] = useState();
  const [highschools, setHighschools] = useState();
  const [formSent, setFormSent] = useState(false);
  const [gameOpen, setGameOpen] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  useEffect(() => {
    getHighSchools()
      .then((res) => {
        setHighschools(res);
      })
      .catch((e) => {
        console.error("Ocurrio un error cargando la lista de liceos.");
      });
  }, []);

  useEffect(() => {
    if (step === 2) {
      getParticipantAmmount();
    }
  }, [step]);

  const sendForm = async () => {
    try {
      setLoading(true);
      const docRef = await addDoc(collection(database, "surprise-guess"), {
        ...data,
        date: Date.now(),
      });
      Bugsnag.notify("Formulario sorpresa", function (event) {
        event.severity = "info";
        event.addMetadata("data", data);
      });
      setFormSent(true);
      setFinishModalOpen(true);
      alert("Formulario enviado correctamente!");
      setLoading(false);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const moveForward = () => {
    setErrorMessage("");
    if (step === 1) {
      setGameOpen(true);
      setStep(step + 1);
    } else if (step === STEPS) {
      sendForm();
    } else {
      setStep(step + 1);
    }
  };

  const getParticipantAmmount = async () => {
    const guessDb = collection(database, "surprise-guess"); // Use collection() for accessing
    const getByDocIdQuery = query(
      guessDb,
      where("participantDocumentId", "==", data.participantDocumentId)
    ); // Create a query

    // Execute the query
    const querySnapshot = await getDocs(getByDocIdQuery);
    const ammount = querySnapshot.docs?.length;
    setData({ ...data, ammount: ammount });
  };

  const onAutoLoad = (name, ammount) => {
    setTimeout(() => {
      alert(`Jugando por un viaje gratis para ${name}! Ingresa tus datos`);
      setStep(step + 1);
    }, 1000);
  };

  const getComponentStep = () => {
    switch (step) {
      case 1:
        return (
          <SurpriseRegister
            data={data}
            onChangeData={(data) => setData(data)}
            onAcceptTermsAndConditions={() =>
              setTermsAndConditionsAccepted(true)
            }
            areTermsAndConditionsAccepted={termsAndConditionsAccepted}
            onParticipantDocId={(val) => setParticipantData(val)}
            participantData={participantData}
            highschools={highschools}
            onAutoLoad={onAutoLoad}
          />
        );
      case 2:
        return (
          <RegisterSecondStep
            data={data}
            onChangeData={(data) => setData(data)}
          />
        );
      case 3:
        return (
          <SurpriseGuess
            category={categories[0]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 4:
        return (
          <SurpriseGuess
            category={categories[1]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 5:
        return (
          <SurpriseGuess
            category={categories[2]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 6:
        return (
          <SurpriseGuess
            category={categories[3]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 7:
        return (
          <SurpriseGuess
            category={categories[4]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 8:
        return (
          <SurpriseGuess
            category={categories[5]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 9:
        return (
          <SurpriseGuess
            category={categories[6]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 10:
        return (
          <SurpriseGuess
            category={categories[7]}
            data={data}
            onChangeData={(data) => setData(data)}
            participantData={participantData}
            gameOpen={gameOpen}
          />
        );
      case 11:
        return (
          <SurpriseResults data={data} participantData={participantData} />
        );
      default:
    }
  };
  const isLastStep = step === STEPS;
  const disabled =
    step === STEPS
      ? false
      : step === 1
      ? data.participantDocumentId?.length === 0 ||
        data.participantPhone?.length === 0 ||
        data.participantBirthDate?.length === 0 ||
        data.highschoolCustom?.length + data.highschool?.length === 0
      : step === 2
      ? data.name?.length === 0 ||
        data.lastName?.length === 0 ||
        data.documentId?.length === 0 ||
        data.birthDate?.length === 0 ||
        data.family?.length === 0 ||
        data?.phone?.length < 6
      : data[categories[step - (STEPS - categories.length)]?.key] === undefined;
  return (
    <div
      style={{
        backgroundImage: `url(${
          isMobile ? backgroundImageMobile : backgroundImage
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {!isLastStep && (
        <h2
          style={{
            color: "white",
            textAlign: isMobile ? "center" : "flex-start",
            paddingBottom: 20,
          }}
        >
          Ganate un viaje gratis a Dinsey!
        </h2>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProgressBar
          className="progress"
          width="100%"
          bgColor={"#00D2B7"}
          completed={
            step * (100 / STEPS) > 100 ? 100 : Math.ceil(step * (100 / STEPS))
          }
        />
      </div>
      {/* {step === 4 && wheelGameOpen ? ( */}
      {step === 5 && wheelGameOpen ? (
        <WheelGame
          modalOpen={true}
          onCloseModal={() => setWheelGameOpen(false)}
          info={data}
          onChangeData={(val) => setData({ ...data, ...val })}
        />
      ) : null}
      <div
        style={{
          marginTop: 20,
          width: "100%",
        }}
      >
        {getComponentStep()}
      </div>
      {/* ) : null} */}
      <h4 style={{ color: warning }}>{errorMessage}</h4>
      <div
        style={{
          alignSelf: "center",
          textAlign: "center",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            {!formSent ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {step > 1 ? (
                  <Button
                    customStyles={{ margin: 5 }}
                    variant="contained"
                    onClick={() => setStep(step - 1)}
                  >
                    <h3 style={{ margin: 0 }}>Anterior</h3>
                  </Button>
                ) : null}
                <Button
                  customStyles={{
                    margin: 5,
                    alignSelf: isMobile ? "center" : null,
                  }}
                  disabled={disabled}
                  variant="contained"
                  onClick={moveForward}
                >
                  <h3 style={{ margin: 0 }}>
                    {step < STEPS ? "Siguiente" : "Terminar"}
                  </h3>
                </Button>
              </div>
            ) : null}
            {loading ? (
              <span style={{ color: "white" }}>Enviando formulario...</span>
            ) : formSent ? (
              <h4 style={{ color: "white" }}>
                Formulario enviado correctamente! Mucha suerte!
              </h4>
            ) : null}
          </div>
        </div>
        {step === 1 && !isMobile ? (
          <div
            style={{
              position: "absolute",
              right: isMobile ? 2 : 10,
              bottom: isMobile ? 2 : 20,
            }}
          >
            <img
              src={minion}
              style={{ height: isMobile ? 50 : 300, width: "auto" }}
              alt=""
            ></img>
          </div>
        ) : null}
        {step === 2 && !isMobile ? (
          <div
            style={{
              position: "absolute",
              left: isMobile ? 2 : 30,
              bottom: isMobile ? 2 : 20,
            }}
          >
            <img
              src={minion2}
              style={{ height: isMobile ? 50 : 300, width: "auto" }}
              alt=""
            ></img>
          </div>
        ) : null}
        {finishModalOpen ? (
          <Modal
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                height: "auto",
                minHeight: 500,
              },
            }}
            isOpen={finishModalOpen}
            ariaHideApp={false}
          >
            <div style={styles.termsContainer}>
              {isMobile ? (
                <img
                  src={finishMobile}
                  alt=""
                  style={{ width: 350, height: "95%" }}
                ></img>
              ) : (
                <img src={finishDesktop} alt="" style={{ width: 1200 }}></img>
              )}
              <Button
                variant="contained"
                style={{ position: "absolute", bottom: 30 }}
                onClick={() => {
                  setFinishModalOpen(false);
                }}
              >
                CERRAR
              </Button>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

export default RegisterScreen;
