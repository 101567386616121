import { Wheel } from "react-custom-roulette";
import Modal from "react-modal";
import { useState, useEffect, useRef } from "react";
import { Button } from "../../ui";
import useMobile from "../../hooks/useOrientation";
import { Alert, LinearProgress } from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { database } from "../../config";

const COLOR1 = "#e545a4";
const COLOR2 = "#e6007e";

const backgroundImage = require("../../assets/roulette-background-desktop.png");
const backgroundImageMobile = require("../../assets/roulette-background-mobile.png");

const roulette = [
  {
    index: 1,
    dbName: "gorro",
    option: "Gorro",
    text: "Felicitaciones! Te ganaste un gorro!",
    probability: 1,
  },
  {
    index: 2,
    dbName: "remera-mickey",
    option: "Remera Mickey",
    text: "Felicitaciones! Te ganaste una remera de Mickey!",
    probability: 2,
  },
  {
    index: 3,
    dbName: "remera-minion",
    option: "Remera Minion",
    text: "Felicitaciones! Te ganaste una remera de Minion!",
    probability: 2,
  },
  {
    index: 6,
    dbName: "tote-bag-negro",
    option: "Tote Bag Negro",
    text: "Felicitaciones! Te ganaste una Tote Bag negra",
    probability: 2,
  },
  {
    index: 7,
    dbName: "tote-bag-verde",
    option: "Tote Bag Verde",
    text: "Felicitaciones! Te ganaste una Tote Bag verde!",
    probability: 1,
  },
  {
    index: 8,
    dbName: "mochila",
    option: "Mochila College",
    text: "Felicitaciones! Te ganaste una mochila de College!",
    probability: 2,
  },
  {
    index: 9,
    dbName: "paraguas",
    option: "Paraguas",
    text: "Felicitaciones! Te ganaste una paraguas de College!",
    probability: 2,
  },
  {
    index: 10,
    dbName: "campera-lluvia",
    option: "Campera de lluvia",
    text: "Felicitaciones! Te ganaste una campera de lluvia de College!",
    probability: 2,
  },
  {
    index: 11,
    dbName: "remera-mickey-celeste",
    option: "Remera Mickey Celeste",
    text: "Felicitaciones! Te ganaste una remera de Mickey celeste!",
    probability: 2,
  },
  {
    index: 12,
    dbName: "remera-mickey-remax",
    option: "Remera Mickey RE MAX",
    text: "Felicitaciones! Te ganaste una remera de Mickey REMAX!",
    probability: 2,
  },
];

const basicOptions = [
  {
    index: 4,
    dbName: "otra-change",
    option: "Otra chance!",
    text: "Otra chance!",
    probability: 2,
  },
  {
    index: 5,
    dbName: "lo-siento",
    option: "Lo siento",
    text: "Lo siento :(",
    probability: 2,
  },
];

const WheelGame = ({ modalOpen, info, onCloseModal, onChangeData, canWin }) => {
  const [gameOpen, setGameOpen] = useState(false);
  const [price, setPrice] = useState();
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState();
  const isMobile = useMobile();
  const values = useRef();

  const styles = {
    termsContainer: {
      backgroundImage: `url(${
        isMobile ? backgroundImageMobile : backgroundImage
      })`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 20,
      paddingTop: 30,
    },
    title: {
      alignSelf: "center",
      textAlign: "center",
      padding: 25,
      color: "white",
    },
  };

  const getPrices = async () => {
    const giftsRef = collection(database, "gifts");
    const getByDocIdQuery = query(giftsRef);
    const querySnapshot = await getDocs(getByDocIdQuery);
    let dataCopy = [];
    querySnapshot.forEach((doc) => {
      Object.keys(doc.data()).forEach((k) => {
        const element = roulette.find((d) => d.dbName === k);
        const newEle = { ...element, ammount: doc.data()[k] };
        dataCopy.push(newEle);
      });
    });

    // Interleave prizes with basicOptions
    const interleavedData = [];
    const maxLength = Math.max(dataCopy.length, basicOptions.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < dataCopy.length) {
        interleavedData.push(dataCopy[i]); // Add a prize
        interleavedData.push(basicOptions[1]); // Add a "Lo siento" or "Otra chance!"
      }
    }

    setData(interleavedData);
  };

  useEffect(() => {
    getPrices();
  }, []);

  const getPassengerPrices = async () => {
    const guessDb = collection(database, "surprise-guess");
    const getByDocIdQuery = query(
      guessDb,
      where("participantDocumentId", "==", info.participantDocumentId)
    );

    const querySnapshot = await getDocs(getByDocIdQuery);

    if (querySnapshot.empty) {
      return [0, false];
    }

    const amount = querySnapshot.docs.length;
    const hasDifferent = querySnapshot.docs.some((doc) => {
      const { priceText } = doc.data();
      return priceText !== null;
    });

    return [amount, hasDifferent];
  };

  const spinRoulette = async () => {
    const prizes = data;
    const [amount, alreadyWon] = await getPassengerPrices();
    const availablePrizes = prizes.filter(
      (prize) => prize.option !== "Lo siento" && prize.ammount > 0
    );

    // If already won or no prizes available, return "Lo siento"
    if (alreadyWon || amount === 0 || availablePrizes.length === 0) {
      const sorryOptions = prizes.filter((p) => p.option === "Lo siento");
      if (sorryOptions.length > 0) {
        const randomIndex = Math.floor(Math.random() * sorryOptions.length);
        return sorryOptions[randomIndex];
      }
    }

    // Third play (amount === 2): guaranteed win
    if (amount === 2 && availablePrizes.length > 0) {
      const totalWeight = availablePrizes.reduce(
        (sum, prize) => sum + prize.probability,
        0
      );
      const randomNum = Math.random() * totalWeight;

      let weightSum = 0;
      for (const prize of availablePrizes) {
        weightSum += prize.probability;
        if (randomNum <= weightSum) {
          return prize;
        }
      }
      return availablePrizes[availablePrizes.length - 1];
    }

    // Second play (amount === 1): 50% chance to win
    if (amount === 1 && availablePrizes.length > 0) {
      if (Math.random() < 0.5) {
        // 50% chance
        const totalWeight = availablePrizes.reduce(
          (sum, prize) => sum + prize.probability,
          0
        );
        const randomNum = Math.random() * totalWeight;

        let weightSum = 0;
        for (const prize of availablePrizes) {
          weightSum += prize.probability;
          if (randomNum <= weightSum) {
            return prize;
          }
        }
        return availablePrizes[availablePrizes.length - 1];
      } else {
        // 50% chance of losing
        const sorryOptions = prizes.filter((p) => p.option === "Lo siento");
        if (sorryOptions.length > 0) {
          const randomIndex = Math.floor(Math.random() * sorryOptions.length);
          return sorryOptions[randomIndex];
        }
      }
    }

    // First play (amount === 0): random chance based on prize probabilities
    if (amount === 0 && availablePrizes.length > 0) {
      const totalWeight = availablePrizes.reduce(
        (sum, prize) => sum + prize.probability,
        0
      );
      const randomNum = Math.random() * (totalWeight + 1); // Adding 1 for losing chance

      let weightSum = 0;
      for (const prize of availablePrizes) {
        weightSum += prize.probability;
        if (randomNum <= weightSum) {
          return prize;
        }
      }
      // If we get here, return "Lo siento" (lost)
      const sorryOptions = prizes.filter((p) => p.option === "Lo siento");
      if (sorryOptions.length > 0) {
        const randomIndex = Math.floor(Math.random() * sorryOptions.length);
        return sorryOptions[randomIndex];
      }
    }

    // Fallback
    const sorryOptions = prizes.filter((p) => p.option === "Lo siento");
    if (sorryOptions.length > 0) {
      return sorryOptions[Math.floor(Math.random() * sorryOptions.length)];
    }
    return prizes[prizes.length - 1];
  };

  const decrementGift = async (giftName) => {
    const giftRef = doc(database, "gifts", "lrBDsBxqU9No9Bc7LXY5"); //TODO: THIS IS THE HARCODED KEY OF PROD GIFTS
    try {
      await runTransaction(database, async (transaction) => {
        const giftDoc = await transaction.get(giftRef);

        if (!giftDoc.exists()) {
          throw new Error("Document does not exist!");
        }

        const giftsData = giftDoc.data();

        if (giftsData[giftName] > 0) {
          transaction.update(giftRef, {
            [giftName]: giftsData[giftName] - 1,
          });
        } else {
          console.log(`No more ${giftName} left!`);
        }
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  };

  const prepareSpin = async () => {
    setEnabled(false);
    const price = await spinRoulette();
    try {
      if (price.dbName !== "lo-siento") {
        await decrementGift(price.dbName);
      }
    } catch {
      console.error("Failed decreasing price amount.");
    }
    setPrice(price.index);
  };

  const onSpinningFinished = () => {
    setTimeout(() => {
      const text = data.find((e) => e.index === price).text;
      alert(text);
      setEnabled(true);
    }, 1000);
  };

  return (
    <Modal
      style={{
        overlay: {
          zIndex: 1001,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "100%" : 1000,
          height: isMobile ? "100%" : "auto",
        },
      }}
      contentLabel="Example Modal"
      isOpen={modalOpen}
      ariaHideApp={false}
    >
      <div style={styles.termsContainer}>
        {data ? (
          <Wheel
            mustStartSpinning={price}
            onStopSpinning={() => onSpinningFinished()}
            prizeNumber={data.findIndex((i) => i.index === price)}
            radiusLineColor="transparent"
            outerBorderColor="transparent"
            innerBorderColor="white"
            data={data}
            fontSize={15}
            backgroundColors={[COLOR1, COLOR2]}
            textColors={["#ffffff"]}
          />
        ) : (
          <LinearProgress />
        )}
        {data ? (
          <div style={{ padding: 20 }}>
            {!price || price === 4 ? (
              <Button
                variant="contained"
                onClick={() => {
                  prepareSpin();
                }}
                disabled={!enabled}
              >
                Girar!
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={!enabled}
                onClick={() => {
                  setGameOpen(false);
                  onChangeData({
                    priceText:
                      data.find((d) => d.index === price).option !== "Lo siento"
                        ? data.find((d) => d.index === price).text
                        : null,
                  });
                  onCloseModal(true);
                }}
              >
                Continuar respondiendo
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default WheelGame;
