import React, { useState, useEffect, useRef, useContext } from "react";
import Bugsnag from "@bugsnag/browser";
import { TextField, InputLabel, CircularProgress } from "@mui/material";
import { createPayment } from "../../services/paymentService";
import { processErrorResponse } from "../../helpers/processErrorResponse";
import { Button, Select } from "../../ui/index";
import FileUploader from "../shared/FileUploader";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { ValidatePaymentAmmount } from "../shared/regex";
import { getPassengerById } from "../../services/passengerService";
import PaymentPlanTable from "./PaymentPlanTable";
import { AbitabButton } from "./AbitabButton";
import { OverlayNotification } from "../OverlayNotification";
import { getUserPaymentDetails } from "../../services/userService";
import PaymentFeesSelector from "./PaymentFeesSelector";
import { toggleOverlay } from "../../store/actions/notificationActions";
import { CenteredLoader } from "../shared/CenteredLoader";
import useMobile from "../../hooks/useOrientation";
import getDailyCurrency from "../../services/currencyService";
import { primary } from "../../ui/colors";
import { useNavigate } from "react-router-dom";
import { getSessionCookie, SessionContext } from "../../helpers/session";
import { jwtDecode } from "jwt-decode";

const UY = "uy";
const USD = "usd";

const paymentOptionsDict = [
  {
    key: UY,
    label: "$",
    default: false,
  },
  {
    key: USD,
    label: "U$S",
    default: true,
  },
];

const paymentSentLabel =
  "Tu pago ha sido correctamente enviado y está pendiente de aprobación hasta que la administración compruebe que la trasferencia ha sido acreditada.";
const paymentSentLabelScreen =
  "Pago enviado. Debera ser aprobado por administracion antes de acreditarse en la cuenta corriente. Ir a Mis Viajes para ver siguientes pagos.";

const AddPayment = () => {
  const { user } = useContext(SessionContext);
  const { showNotification } = useSelector((state) => state.notification);
  const navigate = useNavigate();
  const { id } = user;

  const [paymentData, setPaymentData] = useState({
    ammount: "",
    file: "",
    currency: USD,
    passengerId: null,
    fileType: "",
  });

  const [passenger, setPassenger] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [paymentSent, setPaymentSent] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [uploadedFileType, setUploadedFileType] = useState();
  const storePassengers = useSelector((state) => state.passengers);
  const [passengers, setPassengers] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState(paymentOptionsDict);
  const [paymentFees, setPaymentFees] = useState();
  const [manualPaymentEnabled, setManualPaymentEnabled] = useState(false);
  const [manualPaymentMode, setManualPaymentMode] = useState(false);
  const [currencyNotification, setCurrencyNotification] = useState();
  const [showExchange, setShowExchange] = useState(false);
  const dailyCurrency = useRef();
  const isMobile = useMobile();

  const dispatch = useDispatch();

  const styles = {
    selectPassenger: {
      marginBottom: 10,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
      flex: 1,
    },
    paymentContainer: {
      width: "100%",
      height: "100vh",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      display: showNotification ? "none" : "flex",
    },
    exchangeLabel: {
      color: "white",
      backgroundColor: primary,
      flex: 0,
      padding: 5,
      display: "inline-block",
      borderRadius: 5,
      marginLeft: 10,
    },
    paymentCheckInput: {
      marginBottom: 10,
      marginTop: "20px",
    },
    paymentImageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    paymentImage: { width: 500, height: "auto" },
    loaderContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100vh",
    },
    sendPaymentButton: {
      display: "flex",
      alignSelf: isMobile ? "center" : "flex-start",
      flex: 1,
      justifyContent: isMobile ? "center" : "flex-start",
    },
    seeFile: {
      display: "inline-block",
    },
    ammountInput: { marginRight: 10, width: 200 },
    sendPaymentButtonContainer: {
      marginTop: 10,
      alignSelf: "center",
      display: isMobile ? "flex" : "block",
    },
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { exchange } = await getDailyCurrency();
        dailyCurrency.current = exchange;
      } catch (e) {
        setPaymentOptions(paymentOptionsDict.filter((po) => po.key !== UY));
      }
    }
    fetchData();
  }, []);

  const submitPayment = () => {
    setLoading(true);
    if (!paymentData.passengerId) {
      setError("Elegir pasajero para enviar un pago.");
      setLoading(false);
    } else if (!ValidatePaymentAmmount(paymentData.ammount)) {
      setError("Monto invalido.");
      setLoading(false);
    } else {
      let amount = paymentData.ammount;
      if (paymentData.currency === UY) {
        amount = Math.round(amount / dailyCurrency.current);
      }
      createPayment(id, amount, paymentData)
        .then((res) => {
          setPaymentSent(true);
          dispatch(
            toggleOverlay({
              show: true,
              text: paymentSentLabel,
              success: true,
            })
          );
          setPassenger(null);
          setPaymentData({
            ammount: "",
            file: "",
            passengerId: "",
          });
          setError("");
          setManualPaymentMode(false);
          setLoading(false);
          Bugsnag.notify("Envio de pago.", function (event) {
            event.severity = "info";
            const { UserID, Name, LastName, Email } = jwtDecode(
              getSessionCookie()
            );
            event.setUser(UserID, Email, Name + " " + LastName);
            event.addMetadata("paymentData", paymentData);
          });
        })
        .catch((e) => {
          processErrorResponse(e);
          setLoading(false);
          setError(processErrorResponse(e)[1]);
        });
    }
  };

  const convertPassengersToOptions = () => {
    let options = [];
    storePassengers.passengers.forEach((p) => {
      let passenger = {
        key: p.id,
        label: p.name + " - Viaje: " + p.tripName,
      };
      options.push(passenger);
    });
    return options;
  };

  const handleFileUpload = (file, type) => {
    if (type > -1) {
      setPaymentData({ ...paymentData, ["file"]: file, ["fileType"]: type });
      setUploadedFileType(type);
      setImageLoaded(true);
    } else {
      Bugsnag.notify(
        new Error("Intento subir una imagen con formato invalido.")
      );
      setError("El comprobante de pago debe ser una imagen o un PDF.");
    }
  };

  const handleClickSeeFile = () => {
    window.open(paymentData.file);
  };

  useEffect(() => {
    setPassengers(storePassengers.passengers);
    // console.log(storePassengers.passengers);
    const is2026 = storePassengers?.passengers?.find((p) =>
      p.programName?.includes("2026")
    );
    setManualPaymentEnabled(!is2026);
    if (storePassengers?.passengers?.length === 1) {
      const pass = storePassengers.passengers[0];
      setPaymentData({ ...paymentData, ["passengerId"]: pass.id });
      getPassengerById(pass.id).then((res) => {
        setPassenger(res);
        getUserPaymentDetails(res.documentId).then((pd) => {
          setPaymentFees(pd.payments);
        });
      });
    }
  }, [storePassengers]);

  const handleChangePassenger = (e) => {
    setPaymentFees(null);
    setPaymentSent(false);
    setPaymentData({ ...paymentData, ["passengerId"]: e.target.value });
    getPassengerById(e.target.value).then((res) => {
      setPassenger(res);
      getUserPaymentDetails(res.documentId).then((pd) => {
        setPaymentFees(pd.payments);
      });
    });
  };

  const handleChangeSelectedFees = (value) => {
    //value always comes in dollars
    let val = value;
    if (paymentData.currency === UY) {
      val = val * dailyCurrency.current;
    }
    setPaymentData({
      ...paymentData,
      ["ammount"]: val,
    });
  };

  const handleChangeCurrency = async (e) => {
    const currency = e.target.value;
    const prevCurrency = paymentData.currency;
    let val = paymentData.ammount;
    setShowExchange(currency === UY);
    if (currency === UY && prevCurrency === USD) {
      val = paymentData.ammount * dailyCurrency.current;
      setCurrencyNotification(
        currency === UY ? `1 U$S = ${dailyCurrency.current}$` : ""
      );
    } else {
      if (prevCurrency !== USD) {
        val = paymentData.ammount / dailyCurrency.current;
        setCurrencyNotification("");
      }
    }
    setPaymentData({
      ...paymentData,
      ["ammount"]: Math.round(val),
      ["currency"]: currency,
    });
  };

  const storePass = storePassengers?.passengers;
  const addPaymentLabel =
    storePass?.length > 0
      ? "Ingresar pago para el viaje de " +
        storePass[0].name +
        " " +
        storePass[0].lastName +
        " a " +
        storePass[0].programName
      : null;

  const paymentFeeConverted =
    paymentData.ammount +
    "$ -> " +
    Math.round(paymentData.ammount / dailyCurrency.current) +
    " U$S";

  return (
    <>
      <OverlayNotification />
      <div style={styles.paymentContainer}>
        {passengers?.length && !loading ? (
          <>
            <div style={styles.container}>
              <h1>Pagos</h1>
              <AbitabButton
                abitab
                disabled={!manualPaymentEnabled}
              ></AbitabButton>
              {manualPaymentEnabled ? (
                <AbitabButton
                  onPressButton={() => setManualPaymentMode(true)}
                ></AbitabButton>
              ) : null}
              {!manualPaymentEnabled ? (
                <span style={{ marginTop: 10 }}>
                  Puede realizar el pago en cualquier local de Abitab con el
                  número de cédula del pasajero y el importe que desees abonar.
                  <br></br>Una vez que el pago haya sido acreditado, recibirás
                  un mail con el recibo y el mismo se reflejará en la cuenta
                  corriente.<br></br>También podrás acceder al recibo junto con
                  el historial de pagos en la sección "Mis viajes".
                </span>
              ) : null}
              {manualPaymentMode && (
                <div style={{ marginTop: 20 }}>
                  {storePassengers?.passengers.length > 1 ? (
                    <Select
                      disabled
                      defaultValue={USD}
                      onChange={(e) => handleChangePassenger(e)}
                      placeholder="Elegir pasajero/a"
                      options={
                        storePassengers.passengers
                          ? convertPassengersToOptions()
                          : []
                      }
                      customStyles={{ width: "100%" }}
                    ></Select>
                  ) : (
                    <h4>{addPaymentLabel}</h4>
                  )}

                  {paymentFees?.length === 0 ? (
                    <h3 style={{ marginTop: 20 }}>
                      Tu plan de pagos ha sido completado.
                    </h3>
                  ) : !passenger ? null : paymentFees?.length > 0 ? (
                    <div>
                      <PaymentFeesSelector
                        paymentFees={paymentFees}
                        onChangeFees={handleChangeSelectedFees}
                      ></PaymentFeesSelector>
                      <InputLabel
                        disabled
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <span>Ingrese monto a pagar.</span>
                      </InputLabel>
                      <div style={{ display: "flex", marginBottom: 5 }}>
                        <TextField
                          type="number"
                          variant="outlined"
                          style={styles.ammountInput}
                          onChange={(e) =>
                            setPaymentData({
                              ...paymentData,
                              ["ammount"]: e.target.value,
                            })
                          }
                          placeholder={"Monto"}
                          value={paymentData.ammount}
                        ></TextField>
                        <Select
                          short
                          onChange={(e) => handleChangeCurrency(e)}
                          value={
                            paymentData.currency ? paymentData.currency : ""
                          }
                          options={paymentOptions}
                          placeholder={
                            paymentOptions?.find(
                              (o) => o.key === paymentData.currency
                            )?.label
                          }
                        />
                      </div>
                      <span style={{ fontSize: 14 }}>
                        Asegurarse que el monto ingresado coincide con el
                        importe del comprobante. De lo contrario, deberá subirlo
                        nuevamente.
                      </span>
                      <>
                        <span>{currencyNotification}</span>
                        {showExchange && (
                          <span style={styles.exchangeLabel}>
                            {paymentFeeConverted}
                          </span>
                        )}
                      </>

                      <InputLabel disabled style={styles.paymentCheckInput}>
                        <span>Ingrese comprobante de pago</span>
                      </InputLabel>
                      <FileUploader onFileUploaded={handleFileUpload} />
                      <br />
                      {imageLoaded ? (
                        <div style={styles.seeFile}>
                          {uploadedFileType === 0
                            ? "Imagen cargada correctamente."
                            : "PDF cargado correctamente."}
                          <Button onClick={handleClickSeeFile}>
                            ver archivo
                          </Button>
                        </div>
                      ) : null}
                      <div style={styles.sendPaymentButtonContainer}>
                        <Button
                          disabled={
                            loading || !imageLoaded || paymentData.ammount < 10
                          }
                          onClick={submitPayment}
                          variant="contained"
                          style={styles.sendPaymentButton}
                        >
                          ENVIAR pago
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <CenteredLoader />
                  )}
                </div>
              )}
              {paymentSent ? (
                <div
                  style={{
                    display: isMobile ? "flex" : "inline-block",
                    flexDirection: isMobile ? "column" : "auto",
                    textAlign: isMobile ? "center" : "flex-start",
                  }}
                >
                  <p>{paymentSentLabelScreen}</p>
                  <Button
                    customStyles={{ display: "inline-block" }}
                    onClick={() => navigate("/misViajes")}
                  >
                    IR A MIS VIAJES
                  </Button>
                </div>
              ) : null}
              {error && <h4 style={{ marginTop: 20 }}>{error}</h4>}
            </div>
            <Modal
              contentLabel="Example Modal"
              isOpen={isModalOpen}
              ariaHideApp={false}
              style={{ backgroundColor: "white" }}
            >
              <div style={styles.paymentImageContainer}>
                <Button onClick={() => setIsModalOpen()}>Cerrar</Button>
                <img style={styles.paymentImage} src={paymentData.file} />
                <div>
                  {passenger ? (
                    <PaymentPlanTable passenger={passenger}></PaymentPlanTable>
                  ) : null}
                </div>
              </div>
            </Modal>
          </>
        ) : (
          <div style={styles.loaderContainer}>
            <CircularProgress></CircularProgress>
          </div>
        )}
      </div>
    </>
  );
};

export default AddPayment;
