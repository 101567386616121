import React, { useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { cleanSessionCookie, SessionProvider } from "./helpers/session";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ScreenWithHeader from "./components/ScreenWithHeader";
import LoginScreen from "./components/login/LoginScreen";
import ProfileScreen from "./components/profile/ProfileScreen";
import RoomsScreen from "./components/rooms/RoomsScreen";
import RegisterScreen from "./components/register/RegisterScreen";
import AddPayment from "./components/payment/CreatePayment";
import MyTrips from "./components/trips/MyTrips";
import PageNotFound from "./components/shared/PageNotFound";
import PaymentHistory from "./components/payment/PaymentHistory";
import BasicDocument from "./helpers/pdfGenerator";
import Contact from "./components/contact/Contact";
import RecoverPassword from "./components/login/RecoverPassword";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import Gilroy from "./fonts/Gilroy-Medium.ttf";
import GilroyBold from "./fonts/Gilroy-Bold.ttf";
import { getPassengers } from "./services/passengerService";
import { getGroupByTripId } from "./services/groupService";
import { addPassenger } from "./store/actions/passengerActions";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { OverlayNotification } from "./components/OverlayNotification";
import { processErrorResponse } from "./helpers/processErrorResponse";
import TermsAndConditions from "./components/register/steps/TermsAndConditions";
import AlbumScreen from "./components/album/AlbumScreen";
import CreateRoom from "./components/rooms/CreateRoom";
import Battle from "./components/battle";
import "./config";
import Surprise from "./components/battle/surprise-index";
import WheelGameSingle from "./components/battle/WheelGameSingle";

const STAGE = process.env.REACT_APP_STAGE;

Bugsnag.start({
  apiKey: "ecd72bb59020b566feab9de700c0e356",
  plugins: [new BugsnagPluginReact()],
  releaseStage: STAGE,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function App() {
  const userStore = useSelector((state) => state.user);

  const dispatch = useDispatch();
  // const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
  const theme = createTheme({
    status: {
      danger: "red",
      success: "green",
    },
    palette: {
      primary: {
        main: "#00D2B7",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: Gilroy,
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "dark-mode" },
            style: {
              color: "white",
            },
          },
        ],
      },
      MuiInput: {
        variants: [
          {
            props: { variant: "dark-mode" },
            style: {
              color: `white`,
              background: `#2D2D2D`,
            },
          },
        ],
      },

      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Gilroy';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Gilroy-Medium'), url(${Gilroy}) format('ttf');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          },
          @font-face {
            font-family: 'Gilroy-Bold';
            font-style: bold;
            font-display: swap;
            font-weight: 800;
            src: local('Gilroy-Bold'), url(${GilroyBold}) format('ttf');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
    },
  });

  const router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginScreen></LoginScreen>,
    },
    {
      path: "/registro",
      element: <RegisterScreen></RegisterScreen>,
      errorElement: <PageNotFound />,
    },
    {
      path: "/",
      element: (
        <ScreenWithHeader>
          <ProfileScreen />
        </ScreenWithHeader>
      ),
      errorElement: <PageNotFound />,
    },
    {
      path: "/perfil",
      element: (
        <ScreenWithHeader>
          <ProfileScreen />
        </ScreenWithHeader>
      ),
      errorElement: <PageNotFound />,
    },
    // {
    //   path: "/habitaciones",
    //   element: (
    //     <ScreenWithHeader>
    //       <RoomsScreen />
    //     </ScreenWithHeader>
    //   ),
    //   errorElement: <PageNotFound />,
    // },
    // {
    //   path: "/habitaciones/crear", // Subruta relativa a /habitaciones
    //   element: (
    //     <ScreenWithHeader>
    //       <CreateRoom />
    //     </ScreenWithHeader>
    //   ), // El componente que se renderiza en /habitaciones/crear
    // },
    {
      path: "/pagar",
      element: (
        <ScreenWithHeader>
          <AddPayment />
        </ScreenWithHeader>
      ),
      errorElement: <PageNotFound />,
    },
    {
      path: "/misViajes",
      element: (
        <ScreenWithHeader>
          <MyTrips />
        </ScreenWithHeader>
      ),
      errorElement: <PageNotFound />,
    },
    {
      path: "/historial",
      element: (
        <ScreenWithHeader>
          <PaymentHistory />
        </ScreenWithHeader>
      ),
    },
    {
      path: "/recibo/:id",
      element: <BasicDocument />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/contacto",
      element: (
        <ScreenWithHeader>
          <Contact></Contact>
        </ScreenWithHeader>
      ),
      errorElement: <PageNotFound />,
    },
    {
      path: "/recuperar",
      element: <RecoverPassword />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/terminosycondiciones",
      element: <TermsAndConditions />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/album",
      element: <AlbumScreen />,
      errorElement: <PageNotFound />,
    },
    // {
    //   path: "/batallas",
    //   element: <Battle />,
    //   errorElement: <PageNotFound />,
    // },
    {
      path: "/juego-sorpresa",
      element: <Surprise />,
      errorElement: <PageNotFound />,
    },
    {
      path: "/jugar-ruleta",
      element: <WheelGameSingle />,
      errorElement: <PageNotFound />,
    },
  ]);

  useEffect(() => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    if (user) {
      getPassengers(user.id)
        .then(async (res) => {
          let passengersWithData = [];
          for (let i = 0; i < res.length; i++) {
            const p = res[i];
            const g = await getGroupByTripId(p.tripId, p.groupId);
            const newGroupJson = JSON.parse(
              JSON.stringify(g)
                .replace("name", "groupName")
                .replace("id", "groupId")
            );
            const passengerWithGroup = Object.assign(p, newGroupJson);
            passengersWithData.push(passengerWithGroup);
          }
          dispatch(addPassenger(passengersWithData));
          const { id, name, lastName, email } = user;
          Bugsnag.setUser(id, email, name + " " + lastName);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    }
  }, []);

  useEffect(() => {
    const user = JSON.parse(secureLocalStorage.getItem("user"));
    if (user) {
      getPassengers(user.id)
        .then(async (res) => {
          let passengersWithData = [];
          for (let i = 0; i < res.length; i++) {
            const p = res[i];
            const g = await getGroupByTripId(p.tripId, p.groupId);
            const newGroupJson = JSON.parse(
              JSON.stringify(g)
                .replace("name", "groupName")
                .replace("id", "groupId")
            );
            const passengerWithGroup = Object.assign(p, newGroupJson);
            passengersWithData.push(passengerWithGroup);
          }
          dispatch(addPassenger(passengersWithData));
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    }
  }, [userStore]);

  return (
    <ErrorBoundary>
      <SessionProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router}>
            <div className="App">
              <OverlayNotification />
            </div>
          </RouterProvider>
        </ThemeProvider>
      </SessionProvider>
    </ErrorBoundary>
  );
}

export default App;
