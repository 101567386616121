import React, { useEffect, useState } from "react";
import { TextField, InputLabel } from "@mui/material";
import { NAME, EMAIL, PHONE_NUMBER } from "../../helpers/maxLenghts";
import FileUploader from "../shared/FileUploader";
import AddSignModal from "../payment/AddSingModal";
import { primary } from "../../ui/colors";
import SelectComponent from "../shared/SelectComponent";
import backgroundMobile from "../../assets/AlbumMobileSecond.jpg";
import backgroundDesktop from "../../assets/AlbumDesktopSecond.jpg";
import useMobileDetector from "../../hooks/useMobileDetector";
import { getHighSchools } from "../../services/passengerService";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { database } from "../../config";
import InputMask from "react-input-mask";
import { Button } from "../../ui";
import axios from "axios";
import Modal from "react-modal";
import { categories } from "./surprise-index";

const MARGIN_BOTTOM = 8;
const USD = "usd";

const roulette = [
  {
    index: 0,
    option: "Premio 1",
    style: { backgroundColor: "#00D2B7", textColor: "black" },
  },
  {
    index: 1,
    option: "Premio 2",
    style: { backgroundColor: "white", textColor: "black" },
  },
  {
    index: 2,
    option: "Premio 3",
    style: { backgroundColor: "#00D2B7", textColor: "black" },
  },
  {
    index: 3,
    option: "Premio 4",
    style: { backgroundColor: "white", textColor: "black" },
  },
  {
    index: 4,
    option: "Premio 5",
    style: { backgroundColor: "#00D2B7", textColor: "black" },
  },
  {
    index: 5,
    option: "Premio 6",
    style: { backgroundColor: "white", textColor: "black" },
  },
];

const instagramLogo = require("../../assets/instagram.png");
const tikTokLogo = require("../../assets/tiktok.png");
const ticket = require("../../assets/discount.png");
const termsMobile = require("../../assets/background-terms-mobile.png");
const termsDesktop = require("../../assets/background-terms-background.png");

const years = [
  {
    id: 0,
    name: "Primero",
  },
  {
    id: 1,
    name: "Segundo",
  },
  {
    id: 2,
    name: "Tercero",
  },
  {
    id: 3,
    name: "Cuarto",
  },
  {
    id: 4,
    name: "Quinto",
  },
  {
    id: 5,
    name: "Sexto",
  },
  {
    id: 6,
    name: "No aplica",
  },
];

const family = [
  {
    id: 0,
    name: "Hermano/a",
  },
  {
    id: 1,
    name: "Hija/Hijo",
  },
  {
    id: 2,
    name: "Soy yo",
  },
];

const image = {
  width: "100%",
  height: "auto",
};

const termsText = `
Cada integrante de la familia tiene una oportunidad para jugar. 
Pueden jugar: pasajera, padre, madre y hermanos directos.
Esta información será verificada.
En caso de no cumplir con los requisitos, todas las oportunidades serán invalidadas.
Requisitos: Nacidas en el 2011 (2do de liceo) en adelante.

Si responden correctamente todas las preguntas, ¡felicitaciones! han ganado el gran premio: El viaje a Disney.
Importante:
En caso de que haya más de una familia ganadora, se realizará un sorteo para definir al ganador del viaje entre todas/os las/os ganadoras/es.
Válido para viajes a partir del 2026.

Para que el premio sea válido, la/el pasajer@ debe estar inscripto en el viaje de College a USA 2026/2027, hasta un día antes del anuncio de la sorpresa.
Importante:
En caso de responder correctamente todas las preguntas, la/el pasajer@ no tendrá que abonar el saldo pendiente después de haber realizado el pago de la seña.

Ruleta sorpresa
Los premios que cada familia gane en la ruleta deberán ser retirados en nuestras oficinas (Av. Brasil 3150) o coordinados por teléfono (27080 8888) para su entrega a través de un reparto, con costo a cargo del ganador.
Estos premios serán válidos únicamente para quienes estén inscritos para viajar con College a USA 2026/2027.
En caso de no estar inscritos al momento de jugar y ganar, podrán retirar el premio al momento de la inscripción.
`;

export const SurpriseRegister = ({
  data,
  onChangeData,
  onAcceptTermsAndConditions,
  areTermsAndConditionsAccepted,
  onParticipantDocId,
  participantData,
  onAutoLoad,
}) => {
  const [highschools, setHighschools] = useState();
  const [sent, setSent] = useState();
  const [loading, setLoading] = useState();
  const [editable, setEditable] = useState(false);
  const [validParticipantDocId, setValidParticipantDocId] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [onSpin, setOnSpin] = useState(false);
  const [showRoulette, setShowRoulette] = useState();
  const [writeHighschool, setWriteHighschool] = useState();
  const [autoLoaded, setAutoLoaded] = useState(false);
  const isMobile = useMobileDetector();
  const styles = {
    formContainer: {
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
    dateInput: {
      paddingTop: 16.5,
      paddingBottom: 16.5,
      fontSize: "1rem",
      paddingLeft: 14,
      paddingRight: 14,
      fontFamily: "Gilroy",
      marginBottom: 8,
      backgroundColor: "rgba(255,255,255,0.7)",
      borderWidth: 1,
      borderRadius: 4,
      borderColor: "rgba(0, 0, 0, 0.38)",
    },
    subtitle: {
      alignSelf: "center",
      color: "white",
    },
    sentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    socialContainer: {
      display: "flex",
      gap: 20,
      paddingTop: 20,
    },
    socialLogoStyles: {
      width: 40,
      height: "auto",
      cursor: "pointer",
    },
    termsContainer: {
      backgroundImage: `url(${isMobile ? termsMobile : termsMobile})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      paddingTop: 50,
      paddingBottom: 50,
      overflowY: "auto", // Enable vertical scrolling if needed
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      alignSelf: "center",
      textAlign: "center",
      padding: 25,
      color: "white",
    },
  };

  useEffect(() => {
    getHighSchools()
      .then((res) => {
        setHighschools(res);
      })
      .catch((e) => {
        setWriteHighschool(true);
      });
  }, []);

  const onChangeParticipantDocument = async (value) => {
    onChangeData({
      ...data,
      ["participantDocumentId"]: value,
    });
    if (value.length === 8) {
      const guessDb = collection(database, "surprise-guess"); // Use collection() for accessing
      const getByDocIdQuery = query(
        guessDb,
        where("participantDocumentId", "==", value)
      ); // Create a query

      // Execute the query
      const querySnapshot = await getDocs(getByDocIdQuery);
      const ammount = querySnapshot.docs?.length;

      if (ammount > 0) {
        //TODO: Here get only first. With that is enough
        // You can work with the data here
        const {
          participantBirthDate,
          participantDocumentId,
          participantLastName,
          participantName,
          participantPhone,
          highschoolCustom,
          highschool,
          highschoolName,
          pricesWon,
        } = querySnapshot.docs[0].data();

        onChangeData({
          ...data,
          birthDate: participantBirthDate,
          participantDocumentId: participantDocumentId,
          participantLastName: participantLastName,
          participantName: participantName,
          participantPhone: participantPhone,
          highschoolCustom: highschoolCustom,
          highschool: highschool,
          highschoolName: highschoolName ?? "",
        });
        onAutoLoad(participantName + " " + participantLastName, ammount === 2);
        setAutoLoaded(true);
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {!areTermsAndConditionsAccepted ? (
        <Modal
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 1001,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Optional: adds a dark overlay
            },
            content: {
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              padding: 0,
              border: "none",
              background: "transparent",
              overflow: "hidden",
            },
          }}
          contentLabel="Terms and Conditions"
          isOpen={modalOpen}
          ariaHideApp={false}
        >
          <div
            style={{
              height: "100vh", // Full viewport height
              width: "100vw", // Full viewport width
              backgroundImage: `url(${isMobile ? termsMobile : termsDesktop})`, // Use your background image variables
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                maxHeight: "80vh", // Limits the content height to allow for button space
                width: isMobile ? "90%" : "50%", // Gives some padding from edges
                overflowY: "auto", // Enables vertical scrolling
                backgroundColor: "rgba(255, 255, 255, 0.9)", // Slight white overlay for readability
                padding: "20px",
                marginTop: 120,
                borderRadius: "8px",
                marginBottom: 150, // Space for the button
              }}
            >
              <span
                style={{
                  color: "#000", // Changed to black for better contrast on white background
                  fontSize: 16,
                  lineHeight: 1.5,
                  whiteSpace: "pre-wrap", // This preserves line breaks and spaces
                }}
              >
                {termsText}
              </span>
            </div>
            <Button
              variant="contained"
              style={{
                position: "absolute",
                bottom: "80px",
                padding: "10px 20px",
                backgroundColor: "#yourColorHere", // Customize your button color
              }}
              onClick={() => {
                onAcceptTermsAndConditions();
                setModalOpen(false);
              }}
            >
              DE ACUERDO
            </Button>
          </div>
        </Modal>
      ) : null}
      <div style={styles.formContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            textAlign: "center",
          }}
        >
          <h3 style={styles.subtitle}>
            Datos de la persona que ganaría el viaje - Pasajero College:
          </h3>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.participantDocumentId}
            onChange={(e) => onChangeParticipantDocument(e.target.value)}
            placeholder="Cédula de identidad"
            inputProps={{
              maxLength: 8,
              inputMode: "numeric",
            }}
          ></TextField>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.participantName}
            onChange={(e) =>
              onChangeData({
                ...data,
                ["participantName"]: e.target.value?.toUpperCase(),
              })
            }
            disabled={autoLoaded}
            placeholder="Nombre"
            inputProps={{
              maxLength: 30,
              style: {
                textTransform: "capitalize",
              },
            }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></TextField>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.participantLastName}
            onChange={(e) =>
              onChangeData({
                ...data,
                ["participantLastName"]: e.target.value?.toUpperCase(),
              })
            }
            disabled={autoLoaded}
            placeholder="Apellido"
            inputProps={{
              maxLength: 30,
              style: {
                textTransform: "capitalize",
              },
            }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></TextField>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.participantPhone}
            onChange={(e) =>
              onChangeData({
                ...data,
                ["participantPhone"]: e.target.value,
              })
            }
            disabled={autoLoaded}
            placeholder="Telefono"
            inputProps={{
              maxLength: PHONE_NUMBER,
              inputMode: "numeric",
            }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></TextField>
          <InputMask
            style={styles.dateInput}
            mask="99/99/9999"
            value={data?.participantBirthDate}
            onChange={(val) =>
              onChangeData({
                ...data,
                ["participantBirthDate"]: val.target.value,
              })
            }
            inputProps={{
              maxLength: 9,
              color: "white",
              backgroundColor: "red",
              style: { textTransform: "capitalize", backgroundColor: "red" },
            }}
            disabled={autoLoaded}
            placeholder="Fecha de nacimiento"
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></InputMask>
          {writeHighschool || data.highschoolCustom ? (
            <TextField
              style={{
                marginBottom: MARGIN_BOTTOM,
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: 10,
              }}
              value={data?.highschoolCustom}
              onChange={(e) =>
                onChangeData({
                  ...data,
                  ["highschoolCustom"]: e.target.value,
                })
              }
              disabled={autoLoaded}
              placeholder="Colegio"
              inputProps={{
                maxLength: 30,
                style: {
                  textTransform: "capitalize",
                },
              }}
            ></TextField>
          ) : (
            <>
              <SelectComponent
                onChange={(val) =>
                  onChangeData({
                    ...data,
                    ["highschool"]: val.id,
                    ["highschoolName"]: val.name,
                  })
                }
                label={"Colegio"}
                val={data.highschool}
                data={highschools}
                disabled={autoLoaded}
                filled
              ></SelectComponent>
              <span
                onClick={() => {
                  setWriteHighschool(!writeHighschool);
                }}
                style={{
                  fontWeight: 600,
                  textDecoration: "underline",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Tu colegio no se encuentra en la lista?
              </span>
            </>
          )}
          {participantData ? (
            <div
              style={{
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                gap: 20,
              }}
            >
              <span>
                Estas jugando por un viaje gratis para
                {" " + participantData.nombre + " " + participantData.apellidos}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const RegisterSecondStep = ({ data, onChangeData }) => {
  const [editable, setEditable] = useState(false);

  const isMobile = useMobileDetector();
  const styles = {
    formContainer: {
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
    dateInput: {
      paddingTop: 16.5,
      paddingBottom: 16.5,
      fontSize: "1rem",
      paddingLeft: 14,
      paddingRight: 14,
      fontFamily: "Gilroy",
      marginBottom: 8,
      backgroundColor: "rgba(255,255,255,0.7)",
      borderWidth: 1,
      borderRadius: 4,
      borderColor: "rgba(0, 0, 0, 0.38)",
    },
    subtitle: {
      alignSelf: "center",
      color: "white",
      textAlign: "center",
    },
    title: {
      alignSelf: "center",
      textAlign: "center",
      padding: 25,
      color: "white",
    },
  };

  const onChangeDocument = async (value) => {
    onChangeData({
      ...data,
      ["documentId"]: value,
    });
    if (value.length === 8) {
      // Accessing the collection and querying by documentId
      const guessDb = collection(database, "surprise-guess"); // Use collection() for accessing
      const getByDocIdQuery = query(guessDb, where("documentId", "==", value)); // Create a query

      // Execute the query
      const querySnapshot = await getDocs(getByDocIdQuery);
      let exists = false;

      let documents = [];
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() }); // Include doc ID
      });

      querySnapshot.forEach((doc) => {
        if (doc.data() && doc.data().documentId === value) {
          exists = true;
        }
        // You can work with the data here
      });

      if (exists) {
        alert("Ya hay una entrada para esa cédula de identidad.");
        onChangeData({
          ...data,
          ["documentId"]: "",
        });
        return;
      }
      // axios
      //   .get(`https://ci-uy.checkleaked.cc/${value}`)
      //   .then((res) => {
      //     console.log(res.data?.resp);
      //     const { nombre, apellidos, fechaNacimiento_raw } = res.data?.resp;
      //     setEditable(false);
      //     if (nombre && !fechaNacimiento_raw) {
      //       setEditable(true);
      //     }
      //     onChangeData({
      //       ...data,
      //       ["documentId"]: value,
      //       ["name"]: nombre,
      //       ["lastName"]: apellidos,
      //       ["participantBirthDate"]: fechaNacimiento_raw,
      //     });
      //   })
      //   .catch((e) => {
      //     console.error("CI no encontrada en registro", value, e);
      //   });
    }
  };

  function obtenerOrdinal(numero) {
    // Verificamos que el número esté en el rango válido
    // Array con los ordinales en español
    const ordinales = [
      "Primer",
      "Segundo",
      "Tercer",
      "Cuarto",
      "Quinto",
      "Sexto",
      "Séptimo",
      "Octavo",
      "Noveno",
      "Décimo",
    ];

    // Devolvemos el ordinal correspondiente (restamos 1 porque los arrays empiezan en 0)
    return ordinales[numero] + " intento ";
  }

  const onChangeFamily = (val) => {
    if (val.id === 2) {
      onChangeDocument(data.participantDocumentId);
      const {
        participantDocumentId,
        participantName,
        participantLastName,
        participantBirthDate,
        participantPhone,
      } = data;
      onChangeData({
        ...data,
        documentId: participantDocumentId,
        name: participantName,
        lastName: participantLastName,
        birthDate: participantBirthDate,
        phone: participantPhone,
        family: val.id,
      });
    } else {
      onChangeData({
        ...data,
        documentId: "",
        name: "",
        lastName: "",
        birthDate: "",
        phone: "",
        family: val.id,
      });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <div style={styles.formContainer}>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <h3 style={styles.subtitle}>
            {obtenerOrdinal(data.ammount)}de ganar un viaje gratis para{" "}
            {data.participantName}
          </h3>
          <SelectComponent
            onChange={(val) => onChangeFamily(val)}
            label={data.participantName + " es mi: "}
            val={data.family}
            data={family}
            filled
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></SelectComponent>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.documentId}
            onChange={(e) => onChangeDocument(e.target.value)}
            placeholder="Cédula de identidad"
            inputProps={{
              maxLength: 8,
              inputMode: "numeric",
            }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></TextField>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.name}
            onChange={(e) =>
              onChangeData({ ...data, ["name"]: e.target.value })
            }
            placeholder="Nombre"
            inputProps={{
              maxLength: NAME,
              style: {
                textTransform: "capitalize",
              },
            }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></TextField>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.lastName}
            onChange={(e) =>
              onChangeData({ ...data, ["lastName"]: e.target.value })
            }
            placeholder="Apellido"
            inputProps={{
              maxLength: NAME,
              style: { textTransform: "capitalize" },
            }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></TextField>
          <InputMask
            style={styles.dateInput}
            mask="99/99/9999"
            value={data?.birthDate}
            onChange={(val) =>
              onChangeData({
                ...data,
                ["birthDate"]: val.target.value,
              })
            }
            inputProps={{
              inputMode: "numeric",
              maxLength: 9,
              color: "white",
              backgroundColor: "red",
              style: { textTransform: "capitalize", backgroundColor: "red" },
            }}
            placeholder="Fecha de nacimiento"
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></InputMask>
          <TextField
            style={{
              marginBottom: MARGIN_BOTTOM,
              backgroundColor: "rgba(255,255,255,0.7)",
              borderRadius: 10,
            }}
            value={data?.phone}
            onChange={(e) =>
              onChangeData({
                ...data,
                ["phone"]: e.target.value,
              })
            }
            placeholder="Telefono"
            inputProps={{
              maxLength: PHONE_NUMBER,
              inputMode: "numeric",
            }}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          ></TextField>
          {data.family !== 1 ? (
            <SelectComponent
              onChange={(val) => onChangeData({ ...data, ["year"]: val.id })}
              label={"Año que cursa"}
              val={data.year}
              data={years}
              filled
            ></SelectComponent>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const SurpriseGuess = ({
  category,
  onChangeData,
  data,
  participantData,
  gameOpen,
}) => {
  const isMobile = useMobileDetector();
  const { label, key, title, options, hasBonus } = category;
  const styles = {
    title: {
      alignSelf: "center",
      padding: 25,
      textAlign: "center",
      color: "white",
    },
    formContainer: {
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
  };
  return (
    <div style={styles.formContainer}>
      <h2 style={styles.title}>{title}</h2>
      <SelectComponent
        onChange={(val) =>
          onChangeData({
            ...data,
            [key]: val.id,
            [key + "-text"]: val.name,
          })
        }
        label={title}
        data={options}
        val={data[key]} // Use optional chaining here
      ></SelectComponent>
      {/* {hasBonus ? (
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.7)",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 20,
          }}
        >
          <img style={{ width: 40 }} src={ticket} alt=""></img>
          <span style={{ fontSize: 20 }}>
            Si respondés bien esta pregunta tendrás un descuento de 100 USD en
            el viaje de
            {" " + data?.participantName + " " + data?.participantLastName}
          </span>
        </div>
      ) : null} */}
    </div>
  );
};

export const SurpriseResults = ({ onChangeData, data }) => {
  const isMobile = useMobileDetector();
  const [modalOpen, setModalOpen] = useState(true);
  const styles = {
    title: { alignSelf: "center", padding: 25, color: "white" },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      paddingRight: !isMobile ? 300 : 10,
      paddingLeft: !isMobile ? 300 : 10,
    },
    termsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <div style={styles.formContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 20,
        }}
      >
        <div style={{ flex: 1, alignItems: "center" }}>
          <h2 style={{ fontSize: 20, color: "white" }}>
            <strong>{data.priceText}</strong>
          </h2>
          <br></br>
          <br></br>
          <h3 style={{ color: "white", paddingBottom: 20 }}>
            Sacá una captura de tus resultados para no olvidarte!
          </h3>
          <span style={{ marginTop: 20, fontSize: 20, color: "white" }}>
            Participante:{" "}
            <strong>
              {data.name} {data.lastName}
            </strong>
          </span>
          <br></br>
          <div>
            <span style={{ fontSize: 20, color: "white" }}>
              Días:{" "}
              <strong>
                {categories[0].options.find((c) => c.id === data.days)?.name}
              </strong>
            </span>
            <br></br>
            <span style={{ fontSize: 20, color: "white" }}>
              Fiestas:{" "}
              <strong>
                {categories[1].options.find((c) => c.id === data.partys)?.name}
              </strong>
            </span>
            <br></br>
            <span style={{ fontSize: 20, color: "white" }}>
              Parques:{" "}
              <strong>
                {categories[2].options.find((c) => c.id === data.parks)?.name}
              </strong>
            </span>
            <br></br>
            <span style={{ fontSize: 20, color: "white" }}>
              Ciudades:{" "}
              <strong>
                {categories[3].options.find((c) => c.id === data.cities)?.name}
              </strong>
            </span>
            <br></br>
            <span style={{ fontSize: 20, color: "white" }}>
              Paises:{" "}
              <strong>
                {
                  categories[4].options.find((c) => c.id === data.countries)
                    ?.name
                }
              </strong>
            </span>
            <br></br>

            <span style={{ fontSize: 20, color: "white" }}>
              Famosos:{" "}
              <strong>
                {categories[5].options.find((c) => c.id === data.famous)?.name}
              </strong>
            </span>
            <br></br>
            <span style={{ fontSize: 20, color: "white" }}>
              Deportes:{" "}
              <strong>
                {categories[6].options.find((c) => c.id === data.sports)?.name}
              </strong>
            </span>
            <br></br>
            <span style={{ fontSize: 20, color: "white" }}>
              Transportes:{" "}
              <strong>
                {
                  categories[7].options.find((c) => c.id === data.transport)
                    ?.name
                }
              </strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
