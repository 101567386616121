import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectComponent({
  label,
  onChange,
  data,
  val,
  disabled,
  filled,
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Box
      sx={{ minWidth: 120 }}
      style={{
        marginBottom: 8,
        flex: 1,
        backgroundColor: filled ? "rgba(255,255,255,0.7)" : "white",
        borderRadius: 5,
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          value={data?.find((v) => v.id === val) ?? ""}
          onChange={handleChange}
          disabled={disabled}
        >
          {data ? (
            data.map((e) => {
              return (
                <MenuItem key={e.id} value={e}>
                  {e.name}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem>Cargando...</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
