export function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

export function ValidatePhone(phone) {
  const regex = /^(?:\+598|0)[2-9]\d{7}$|^(?:\+54|0)(?:[1-9]\d{1})?\d{8}$/;
  return regex.test(phone);
}

export function ValidatePassword(password) {
  return password.length > 3 && password.length < 50;
}

export function ValidatePaymentAmmount(ammount) {
  const regex = /^\d{2,7}$/;
  if (regex.test(ammount)) {
    return true;
  }
  return false;
}

export function ValidateCI(ci) {
  // Check if length is 8 and contains only digits
  return ci.length < 20 && /^\d{8}$/.test(ci);
}

export function ValidateDate(date) {
  if (
    /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(date)
  ) {
    return true;
  }
  return false;
}

export function ValidateNumbers(data) {
  const onlyNumbersRegex = /^\d+$/;
  return onlyNumbersRegex.test(data);
}
