import { Wheel } from "react-custom-roulette";
import Modal from "react-modal";
import { useState, useEffect, useRef } from "react";
import { Button } from "../../ui";
import useMobile from "../../hooks/useOrientation";
import { Alert, Input, LinearProgress } from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { database } from "../../config";

const COLOR1 = "#e545a4";
const COLOR2 = "#e6007e";

const backgroundImage = require("../../assets/roulette-background-desktop.png");
const backgroundImageMobile = require("../../assets/roulette-background-mobile.png");

const roulette = [
  {
    index: 1,
    dbName: "gorro",
    option: "Gorro",
    text: "Felicitaciones! Te ganaste un gorro!",
    probability: 1,
    ammount: 0,
  },
  {
    index: 2,
    dbName: "sorry-1",
    option: "Lo siento",
    text: "Lo siento, no ganaste esta vez.",
    probability: 1,
    ammount: 0, // "Lo siento" doesn't need stock
  },
  {
    index: 3,
    dbName: "remera-mickey",
    option: "Remera Mickey",
    text: "Felicitaciones! Te ganaste una remera de Mickey!",
    probability: 1,
    ammount: 0,
  },
  {
    index: 4,
    dbName: "sorry-2",
    option: "Lo siento",
    text: "Lo siento, no ganaste esta vez.",
    probability: 1,
    ammount: 0,
  },
  {
    index: 5,
    dbName: "remera-minion",
    option: "Remera Minion",
    text: "Felicitaciones! Te ganaste una remera de minion!",
    probability: 1,
    ammount: 0,
  },
  {
    index: 6,
    dbName: "sorry-3",
    option: "Lo siento",
    text: "Lo siento, no ganaste esta vez.",
    probability: 1,
    ammount: 0,
  },
  {
    index: 7,
    dbName: "tote-bag-verde",
    option: "Tote Bag Verde",
    text: "Felicitaciones! Te ganaste una Tote Bag Verde!",
    probability: 1,
    ammount: 0,
  },
  {
    index: 8,
    dbName: "sorry-4",
    option: "Lo siento",
    text: "Lo siento, no ganaste esta vez.",
    probability: 1,
    ammount: 0,
  },
  {
    index: 9,
    dbName: "tote-bag-negro",
    option: "Tote Bag Negro",
    text: "Felicitaciones! Te ganaste una Tote Bag Negro!",
    probability: 1,
    ammount: 0,
  },
  {
    index: 10,
    dbName: "sorry-5",
    option: "Lo siento",
    text: "Lo siento, no ganaste esta vez.",
    probability: 1,
    ammount: 0,
  },
];
const WheelGameSingle = ({
  modalOpen,
  info,
  onCloseModal,
  onChangeData,
  canWin,
}) => {
  const [price, setPrice] = useState();
  const [enabled, setEnabled] = useState(true);
  const [data, setData] = useState(roulette);
  const [pricesSetterOpen, setPricesSetterOpen] = useState(true);
  const isMobile = useMobile();
  const values = useRef();
  const styles = {
    termsContainer: {
      backgroundImage: `url(${
        isMobile ? backgroundImageMobile : backgroundImage
      })`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      height: "100vh",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 20,
      paddingTop: 30,
    },
    title: {
      alignSelf: "center",
      textAlign: "center",
      padding: 25,
      color: "white",
    },
  };

  const getRandomPrize = (prizes) => {
    // Filter prizes with amount > 0
    const availablePrizes = prizes.filter((prize) => prize.ammount > 0);

    // If no prizes are available, return null or throw an error
    if (availablePrizes.length === 0) {
      alert("No hay mas premios :(");
      return null; // or throw new Error("No prizes available")
    }

    // Calculate total probability weight
    const totalWeight = availablePrizes.reduce(
      (sum, prize) => sum + prize.probability,
      0
    );

    // Generate random number between 0 and totalWeight
    const randomNum = Math.random() * totalWeight;

    // Select prize based on weighted probability
    let cumulativeWeight = 0;
    for (const prize of availablePrizes) {
      cumulativeWeight += prize.probability;
      if (randomNum <= cumulativeWeight) {
        return prize;
      }
    }

    // Fallback (shouldn't reach here if probabilities are set correctly)
    return availablePrizes[availablePrizes.length - 1];
  };

  const spinRoulette = async () => {
    const price = getRandomPrize(data);
  };

  const decrementGift = async (giftName) => {};

  const prepareSpin = async () => {
    setEnabled(false);
    const price = getRandomPrize(data);
    let newPrices = [...data];
    newPrices[price.index - 1].ammount = newPrices[price.index - 1].ammount - 1;
    setData(newPrices);
    setPrice(price.index);
  };

  const onSpinningFinished = () => {
    setTimeout(() => {
      const text = data.find((e) => e.index === price).text;
      alert(text);
      setEnabled(true);
      setPrice(null);
    }, 1000);
  };

  const onClosePricesSetterOpen = () => {
    // console.log("Falta: ", empty);
    const emptyPrices = data.filter((p) => p.ammount === 0);
    if (data.length === emptyPrices.length) {
      alert("Los premios no pueden estar todos vacios");
    } else {
      setPricesSetterOpen(false);
    }
  };

  return (
    <div style={styles.termsContainer}>
      {pricesSetterOpen ? (
        <Modal
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: isMobile ? "80%" : 500,
              zIndex: 1000,
              height: isMobile ? "70%" : "100",
            },
          }}
          contentLabel="Example Modal"
          isOpen={true}
          ariaHideApp={false}
        >
          <div
            style={{
              padding: 10,
              height: 400,
              width: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {roulette.map((p) => {
              if (p.option !== "Lo siento") {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: 10,
                    }}
                  >
                    <span>Cantidad de {p.option}</span>
                    <Input
                      type="number"
                      variant="contained"
                      style={{ padding: 10, alignSelf: "flex-end" }}
                      onChange={(e) => {
                        let dataCopy = [...roulette];
                        dataCopy[p.index - 1].ammount = parseInt(
                          e.target.value
                        );
                        setData(dataCopy);
                      }}
                    ></Input>
                  </div>
                );
              }
            })}
            <div>
              <span>Cantidad de Lo Siento</span>
              <Input
                type="number"
                variant="contained"
                style={{ padding: 10, alignSelf: "flex-end" }}
                onChange={(e) => {
                  let diff = 0;
                  let dataCopy = [...roulette];
                  let loSientoAmount = roulette.filter(
                    (o) => o.option === "Lo siento"
                  ).length;
                  let loSientoAmmountEach =
                    e.target.value > loSientoAmount
                      ? Math.floor(e.target.value / loSientoAmount)
                      : 1;
                  if (e.target.value > loSientoAmount) {
                    diff = e.target.value % loSientoAmount;
                  }
                  console.log(loSientoAmmountEach + diff);
                  const newData = dataCopy.map((o, index) => {
                    if (o.option === "Lo siento") {
                      return {
                        ...o,
                        ammount:
                          index === 1
                            ? loSientoAmmountEach + diff
                            : loSientoAmmountEach,
                      };
                    } else {
                      return { ...o };
                    }
                  });
                  console.log("new data", newData);
                  setData(newData);
                }}
              ></Input>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 50,
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => {
                  onClosePricesSetterOpen();
                }}
              >
                Guardar
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
      {!pricesSetterOpen ? (
        <Wheel
          mustStartSpinning={price}
          onStopSpinning={() => onSpinningFinished()}
          prizeNumber={data.findIndex((i) => i.index === price)}
          radiusLineColor="transparent"
          outerBorderColor="transparent"
          innerBorderColor="white"
          data={data}
          fontSize={18}
          backgroundColors={[COLOR1, COLOR2]}
          textColors={["#ffffff"]}
        />
      ) : (
        <LinearProgress />
      )}
      <div style={{ padding: 20 }}>
        <Button
          variant="contained"
          onClick={() => {
            prepareSpin();
          }}
          disabled={!enabled}
          customStyles={{ fontSize: 40 }}
        >
          Girar!
        </Button>
      </div>
    </div>
  );
};

export default WheelGameSingle;
