import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import SelectComponent from "../../shared/SelectComponent";
import { NAME, PHONE_NUMBER, ID } from "../../../helpers/maxLenghts";
import { getHighSchools } from "../../../services/passengerService";
import "dayjs/locale/en-gb";
import "react-datepicker/dist/react-datepicker.css";
import "../../../App.css";
import InputMask from "react-input-mask";
import axios from "axios";

const styles = {
  dateInput: {
    paddingTop: 16.5,
    paddingBottom: 16.5,
    fontSize: "1rem",
    paddingLeft: 14,
    paddingRight: 14,
    fontFamily: "Gilroy",
    marginBottom: 8,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.38)",
  },
};
const MARGIN_BOTTOM = 8;
const Passenger = ({ data, onChangeData }) => {
  const [highschools, setHighschools] = useState();
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    getHighSchools()
      .then((res) => {
        setHighschools(res);
      })
      .catch((e) => {
        setError("Ocurrio un error cargando la lista de liceos.");
      });
  }, []);

  const onChangeDocument = (value) => {
    onChangeData({
      ...data,
      ["documentId"]: value,
    });
    if (value.length === 8) {
      axios
        .get(`https://ci-uy.checkleaked.cc/${value}`)
        .then((res) => {
          const { nombre, apellidos, fechaNacimiento_raw } = res.data?.resp;
          setEditable(false);
          if (nombre && !fechaNacimiento_raw) {
            setEditable(true);
          }
          onChangeData({
            ...data,
            ["documentId"]: value,
            ["name"]: nombre,
            ["lastName"]: apellidos,
            ["birthDate"]: fechaNacimiento_raw,
          });
        })
        .catch((e) => {
          console.error("CI no encontrada en registro", value);
        });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 className="verticalPaddingText">Completa los datos del pasajero.</h3>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.documentId}
        onChange={(e) => onChangeDocument(e.target.value)}
        placeholder="Cedula"
        inputProps={{ maxLength: ID }}
      ></TextField>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.name}
        onChange={(e) => onChangeData({ ...data, ["name"]: e.target.value })}
        placeholder="Nombre"
        // disabled={true}
        inputProps={{ maxLength: NAME, style: { textTransform: "capitalize" } }}
      ></TextField>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.lastName}
        onChange={(e) =>
          onChangeData({ ...data, ["lastName"]: e.target.value })
        }
        placeholder="Apellido"
        // disabled={true}
        inputProps={{ maxLength: NAME, style: { textTransform: "capitalize" } }}
      ></TextField>
      <InputMask
        style={styles.dateInput}
        mask="99/99/9999"
        value={data.birthDate}
        onChange={(val) =>
          onChangeData({ ...data, ["birthDate"]: val.target.value })
        }
        // disabled={!editable}
        placeholder="Fecha de nacimiento"
      ></InputMask>
      <TextField
        style={{ marginBottom: MARGIN_BOTTOM }}
        value={data.phone}
        onChange={(e) =>
          onChangeData({
            ...data,
            ["phone"]: e.target.value,
          })
        }
        placeholder="Telefono"
        inputProps={{ maxLength: 30 }}
      ></TextField>
      <SelectComponent
        onChange={(val) => onChangeData({ ...data, ["highschool"]: val.id })}
        label={"Colegio"}
        val={data.highschool}
        data={highschools}
      ></SelectComponent>
      {error && { error }}
    </div>
  );
};

export default Passenger;
