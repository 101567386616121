import React from "react";
import { useDispatch } from "react-redux";
import useMobile from "../../hooks/useOrientation";
import { toggleOverlay } from "../../store/actions/notificationActions";
import { primary } from "../../ui/colors";

export const AbitabButton = ({ abitab, onPressButton, disabled }) => {
  const text =
    "Puede realizar el pago en cualquier local de Abitab con el número de cédula del pasajero y el importe que desees abonar. Una vez que el pago haya sido acreditado, recibirás un mail con el recibo y el mismo se reflejará en la cuenta corriente. También podrás acceder al recibo junto con el historial de pagos en la sección Mis viajes";
  const img = require("../../assets/abitab-logo.png");
  const transferencias = require("../../assets/transferencias.png");
  const dispatch = useDispatch();
  const isMobile = useMobile();

  const styles = {
    container: {
      width: 200,
      height: 60,
      alignSelf: isMobile ? "center" : "left",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: abitab ? "1px solid black" : "none",
      borderRadius: 10,
      marginBottom: 5,
      cursor: "pointer",
      backgroundColor: abitab ? "white" : primary,
    },
    image: {
      width: "90%",
    },
    text: {
      marginVertical: 0,
    },
    logoCollege: {
      width: 100,
    },
  };

  const handlePress = () => {
    if (abitab) {
      dispatch(toggleOverlay({ show: true, text: text }));
    } else {
      onPressButton();
    }
  };

  return (
    <button style={styles.container} onClick={handlePress} disabled={disabled}>
      {abitab ? (
        <img src={img} alt="" style={styles.image}></img>
      ) : (
        <>
          <img style={styles.logoCollege} src={transferencias}></img>
          <h3 style={{ color: "black", fontSize: 14 }}>Subir comprobante</h3>
        </>
      )}
    </button>
  );
};
